const propertiesRoutes = {
  path: "/accounts/:account",
  component: () => import("@/views/NestedRoute.vue"),
  children: [
    {
      name: "account-properties",
      path: "properties",
      component: () => import("@/views/property/Properties.vue"),
      meta: {
        protected: true,
      },
      children: [
        {
          name: "property-create",
          path: "create",
          component: () => import("@/views/property/Create.vue"),
          meta: {
            protected: true,
          },
        },
        {
          path: ":property",
          component: () => import("@/views/NestedRoute.vue"),
          children: [
            {
              name: "property-rename",
              path: "rename",
              component: () => import("@/views/property/Rename.vue"),
              meta: {
                protected: true,
              },
            },
            {
              name: "property-manage-subscriptions",
              path: "manage-subscriptions",
              component: () =>
                import("@/views/property/ManageSubscriptions.vue"),
              meta: {
                protected: true,
              },
            },
            {
              name: "property-invite-user",
              path: "invite-user",
              component: () => import("@/views/property/InviteUser.vue"),
              meta: {
                protected: true,
              },
            },
            {
              name: "property-update-contact",
              path: "update-contact",
              component: () => import("@/views/property/UpdateContact.vue"),
              meta: {
                protected: true,
              },
            },
          ],
        },
      ],
    },
  ],
}

export default propertiesRoutes
