const routes = {
  name: "search-results",
  path: "/search-results",
  component: () => import("@/views/search-results/searchResults.vue"),
  meta: {
    protected: true,
    allMode: true,
  },
};

export default routes;
