const routes = {
  name: "pending-installations",
  path: "/pending-installations",
  component: () =>
    import("@/views/pending-installations/ListPendingInstallations.vue"),
  meta: {
    protected: true,
    resource: "Accounts",
    action: "list",
    allMode: true,
  },
  children: [
    {
      name: "pending-installations-entrance-create",
      path: "create-entrance/:account/:property/:building",
      component: () => import("@/views/entrance/Create.vue"),
      meta: {
        protected: true,
      },
    },
  ],
};

export default routes;
