const buildingDetailRoutes = {
  name: "building-details",
  path: "/accounts/:account/properties/:property/buildings/:building/details",
  component: () => import("@/views/entrance/Entrances.vue"),
  meta: {
    protected: true,
  },
  children: [
    {
      name: "entrance-create",
      path: "entrances/create",
      component: () => import("@/views/entrance/Create.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "entrance-preview",
      path: "entrances/:entrance/preview",
      component: () => import("@/views/entrance/Preview.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "view-entrance-camera",
      path: "entrances/:entrance/view-camera",
      component: () => import("@/views/entrance/ViewEntranceCamera.vue"),
      meta: {
        protected: true,
      },
    },
    {
      path: "entrances/:entrance",
      component: () => import("@/views/NestedRoute.vue"),
      children: [],
      meta: {
        protected: true,
      },
    },
    {
      name: "building-unit-create",
      path: "units/create",
      component: () => import("@/views/units/ResidenceCreate.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "building-unit-scoped",
      path: "units/:unit",
      component: () => import("@/views/NestedRoute.vue"),
      meta: {
        protected: true,
      },
      children: [
        {
          name: "building-unit-update-details",
          path: "updateDetails",
          component: () => import("@/views/units/ResidenceUpdateDetails.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-unit-remove",
          path: "remove",
          component: () => import("@/views/units/ResidenceRemove.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-unit-add-contact",
          path: "addContact",
          component: () => import("@/views/contacts/ContactCreate.vue"),
          meta: {
            protected: true,
          },
        },
      ],
    },
    {
      name: "building-contact-scoped",
      path: "contacts/:contact",
      component: () => import("@/views/NestedRoute.vue"),
      meta: {
        protected: true,
      },
      children: [
        {
          name: "building-contact-update-details",
          path: "updateDetails",
          component: () => import("@/views/contacts/ContactUpdateDetails.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-contact-remove",
          path: "remove",
          component: () => import("@/views/contacts/ContactRemove.vue"),
          meta: {
            protected: true,
          },
        },
      ],
    },
  ],
};

export default buildingDetailRoutes;
