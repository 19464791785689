const buildingsRoutes = {
  name: "property-buildings",
  path: "/accounts/:account/properties/:property/buildings",
  component: () => import("@/views/building/Buildings.vue"),
  meta: {
    protected: true,
  },
  children: [
    {
      name: "building-create",
      path: "create",
      component: () => import("@/views/building/Create.vue"),
      meta: {
        protected: true,
      },
    },
    {
      path: ":building",
      component: () => import("@/views/NestedRoute.vue"),
      children: [
        {
          name: "building-add-subscriptions-step1",
          path: "add-subscriptions-step-1",
          component: () =>
            import("@/views/building/add-subscriptions/Step1.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-add-subscriptions-step2",
          path: "add-subscriptions-step-2",
          component: () =>
            import("@/views/building/add-subscriptions/Step2.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-residents",
          path: "residents",
          component: () => import("@/views/building/Resident.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-configure-entrances",
          path: "configure-entrances",
          component: () => import("@/views/building/ConfigureEntrances.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-rename",
          path: "rename",
          component: () => import("@/views/building/Rename.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-update-images",
          path: "updateImages",
          component: () => import("@/views/building/UpdateImages.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "building-update-contact",
          path: "update-contact",
          component: () => import("@/views/building/UpdateContact.vue"),
          meta: {
            protected: true,
          },
        },
      ],
    },
  ],
};

export default buildingsRoutes;
