export function addPropertyMessage(
  state,
  { message, btnText, btnFunction, btnTooltip }
) {
  state.propertyMessages.push({
    message,
    btnText,
    btnFunction,
    btnTooltip,
  })
}

export function deletePropertyMessages(state) {
  state.propertyMessages = []
}
