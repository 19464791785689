import Vue from "vue";
import VueRouter from "vue-router";

import resellersRoutes from "./routes/resellers";
import allResellersRoutes from "./routes/all-resellers";
import accountsRoutes from "./routes/accounts";
import allAccountsRoutes from "./routes/all-accounts";
import partnersRoutes from "./routes/partners";
import propertiesRoutes from "@/router/routes/properties";
import buildingsRoutes from "@/router/routes/buildings";
import buildingDetailRoutes from "@/router/routes/building-details";
import buildingAllUnits from "@/router/routes/building-allunits";
import pendingInstallationsRoutes from "@/router/routes/pending-installations";
import globalSearch from "@/router/routes/global-search";
import buildingEntrancesRoutes from "@/router/routes/building-entrances";

import { isAuthenticatedGuard } from "@frontegg/vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        protected: true,
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    { ...resellersRoutes },
    { ...allResellersRoutes },
    { ...accountsRoutes },
    { ...allAccountsRoutes },
    { ...propertiesRoutes },
    { ...buildingsRoutes },
    { ...buildingDetailRoutes },
    { ...pendingInstallationsRoutes },
    { ...globalSearch },
    { ...partnersRoutes },
    { ...buildingAllUnits },
    { ...buildingEntrancesRoutes },
    {
      name: "building-entry-logs",
      path: "/accounts/:account/properties/:property/buildings/:building/details/logs",
      component: () => import("@/views/building/EntryLogs.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "all-buildings-entry-logs",
      path: "/accounts/:account/properties/:property/logs",
      component: () => import("@/views/building/EntryLogs.vue"),
      meta: {
        protected: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let wrappedNext = next;
  try {
    let stickyQuery = {};
    if (to.query.reseller == null && from.query.reseller != null) {
      if (to.name != "manage-resellers" && to.name != "manage-partners") {
        stickyQuery.reseller = from.query.reseller;
      }
    }

    if (to.query.partner == null && from.query.partner != null) {
      if (to.name != "manage-partners") {
        stickyQuery.partner = from.query.partner;
      }
    }

    if (stickyQuery.reseller || stickyQuery.partner) {
      wrappedNext = () =>
        next({ path: to.path, query: { ...to.query, ...stickyQuery } });
    }

    if (to.matched && to.matched.length > 0 && to.matched[0]) {
      if (to.matched[0].meta.protected) {
        return isAuthenticatedGuard(to, from, wrappedNext);
      }
    }
  } catch (error) {
    console.error(error);
  }

  return wrappedNext();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export const addNotFoundRoutes = () => {
  router.addRoutes([
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ]);
};

export default router;
