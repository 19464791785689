import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import breadcumMessage from "./breadcumMessages";
import sideAbout from "./sideAbout";
import signals from "./signals";
import selectedData from "./selectedData";
import entrances from "./entrances";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    entrances,
    verticalMenu,
    auth,
    breadcumMessage,
    sideAbout,
    signals,
    selectedData,
  },
  strict: process.env.DEV,
});
