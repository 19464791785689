const partnersRoutes = {
  name: 'manage-partners',
  path: '/partners',
  component: () => import('@/views/partners/Partners.vue'),
  meta: {
    protected: true,
    resource: 'Partners',
    action: 'list',
  },
  children: [
    {
      name: 'partner-invite-user',
      path: ':partner/invite-user',
      component: () => import('@/views/partners/InviteUser.vue'),
      meta: {
        protected: true,
      },
    },
  ]
}

export default partnersRoutes