export function setTenantInfo(state, tenantInfo) {
  state.TenantInfo = tenantInfo ? Object.assign({}, tenantInfo) : null
}
export function setPartnerInfo(state, partnerInfo) {
  state.PartnerInfo = partnerInfo ? Object.assign({}, partnerInfo) : null
}
export function setResellerInfo(state, resellerInfo) {
  state.ResellerInfo = resellerInfo ? Object.assign({}, resellerInfo) : null
}
export function setAccountInfo(state, accountInfo) {
  state.AccountInfo = accountInfo ? Object.assign({}, accountInfo) : null
}
export function setPropertyInfo(state, propertyInfo) {
  state.PropertyInfo = propertyInfo ? Object.assign({}, propertyInfo) : null
}
export function setBuildingInfo(state, buildingInfo) {
  state.BuildingInfo = buildingInfo ? Object.assign({}, buildingInfo) : null
}
export function setEntranceInfo(state, entranceInfo) {
  state.EntranceInfo = entranceInfo ? Object.assign({}, entranceInfo) : null
}
