export const setSelectedUnit = async ({ commit }, newValue) => {
  commit('setSelectedUnit', newValue)
}

export const clearSelectedUnit = async ({ commit }) => {
  commit('clearSelectedUnit')
}

export const setSelectedAccount = async ({ commit }, newValue) => {
  commit('setSelectedAccount', newValue)
}

export const clearSelectedAccount = async ({ commit }) => {
  commit('clearSelectedAccount')
}

export const setSelectedProperty = async ({ commit }, newValue) => {
  commit('setSelectedProperty', newValue)
}

export const clearSelectedProperty = async ({ commit }) => {
  commit('clearSelectedProperty')
}
