import propertiesRoutes from '@/router/routes/properties'

const accountsRoutes = {
  name: 'manage-accounts',
  path: '/accounts',
  component: () => import('@/views/account/ManageAccounts.vue'),
  meta: {
    protected: true,
    resource: 'Accounts',
    action: 'list',
  },
  children: [
    {
      name: 'account-create',
      path: 'create',
      component: () => import('@/views/account/Create.vue'),
      meta: {
        protected: true,
      },
    },
    {
      name: 'invite-reseller-from-accounts',
      path: 'invite-reseller',
      component: () => import('@/views/account/InviteUser.vue'),
      meta: {
        protected: true,
      },
    },
    {
      path: ':account',
      component: () => import('@/views/NestedRoute.vue'),
      children: [
        {
          name: 'account-rename',
          path: 'rename',
          component: () => import('@/views/account/Rename.vue'),
          meta: {
            protected: true,
          },
        },
        {
          name: 'account-change-billing',
          path: 'change-settlement',
          component: () => import('@/views/account/ChangeBilling.vue'),
          meta: {
            protected: true,
          },
        },
        {
          name: 'account-invite-user',
          path: 'invite-user',
          component: () => import('@/views/account/InviteAccount.vue'),
          meta: {
            protected: true,
          },
        },
      ],
    },
  ],
}

export default accountsRoutes
