export const setTenantInfo = async ({ commit }, partnerInfo) => {
  commit("setTenantInfo", partnerInfo)
}
export const setPartnerInfo = async ({ commit }, partnerInfo) => {
  commit("setPartnerInfo", partnerInfo)
}
export const setResellerInfo = async ({ commit }, resellerInfo) => {
  commit("setResellerInfo", resellerInfo)
}
export const setAccountInfo = async ({ commit }, accountInfo) => {
  commit("setAccountInfo", accountInfo)
}
export const setPropertyInfo = async ({ commit }, propertyInfo) => {
  commit("setPropertyInfo", propertyInfo)
}
export const setBuildingInfo = async ({ commit }, buildingInfo) => {
  commit("setBuildingInfo", buildingInfo)
}
export const setEntranceInfo = async ({ commit }, entranceInfo) => {
  commit("setEntranceInfo", entranceInfo)
}
