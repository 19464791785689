<template>
  <div id="app" class="h-100" :class="[skinClasses]" v-if="fronteggLoaded">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { watch } from "vue"
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig"
import { provideToast } from "vue-toastification/composition"

import useAppConfig from "@core/app-config/useAppConfig"
import { useWindowSize, useCssVar } from "@vueuse/core"

import store from "@/store"

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue")
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue")
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue")

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  data() {
    return {
      ...this.mapAuthState(),
      ...this.mapTenantsState(),
    }
  },
  watch: {
    "authState.user"(user) {
      if (user) {
        this.$apiClient.defaults.headers.Authorization = `Bearer ${user.accessToken}`
      }
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      // return 'layout-full'
      if (
        !Object.keys(this.$route.meta).length ||
        this.$route.meta.layout === "full"
      )
        return "layout-full"
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr")
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout")

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    // provideToast({
    //   hideProgressBar: true,
    //   closeOnClick: false,
    //   closeButton: false,
    //   icon: false,
    //   timeout: 3000,
    //   transition: "Vue-Toastification__fade",
    // })

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val)
    })
    return {
      skinClasses,
    }
  },
}
</script>

<style>
.h2,
h2 {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}
.h3,
h3 {
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

.card .card-title {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.h1,
h1 {
  font-size: 1.3rem !important;
  font-weight: bold !important;
}
.elevated-btn {
  box-shadow: 0 2px 4px 0 rgba(152, 152, 152, 0.5);
}
.absolute-top-right {
  position: absolute;
  top: 3px;
  right: 3px;
}
.bg-gray-100 {
  background-color: #f8f8f8 !important;
}

.building-name {
  font-size: 2rem !important;
}

.loading-overlay {
  position: absolute;
  top: -45px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: 700;
  font-size: 20px;
  border-radius: 4px;
  width: 100%;
  z-index: 999;
  height: calc(100% + 74.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
