export const addPropertyMessage = async ({ commit }, { message, btnText, btnFunction, btnTooltip }) => {
  const defaultEmptyFunction = () => {}
  commit('addPropertyMessage', {
    message,
    btnText,
    btnFunction: btnFunction || defaultEmptyFunction,
    btnTooltip: btnTooltip || '',
  })
}

export const deletePropertyMessages = async ({ commit }) => {
  commit('deletePropertyMessages')
}