import axios from "axios";
import Vue from "vue";
import store from "@/store";
import router from "@/router";

export default {
  install(Vue) {
    Vue.prototype.$apiClient = Vue.$apiClient = axios.create();
    Vue.$apiClient.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.$apiClient.defaults.headers = {
      "Content-Type": "application/json",
    };
    Vue.$apiClient.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          router.push("/account/login").catch(() => {});
        }
      }
    );
  },

  installInterceptors() {
    store.watch(
      (state) => {
        return state.auth.token;
      },
      (token) => {
        Vue.authHttp.defaults.headers.Authorization = `Bearer ${token}`;
      },
      {
        deep: true,
        immediate: true,
      }
    );
  },
};
