const resellersRoutes = {
  name: "manage-resellers",
  path: "/resellers",
  component: () => import("@/views/reseller/ManagerResellers.vue"),
  meta: {
    protected: true,
  },
  children: [
    {
      name: "reseller-create",
      path: "create",
      component: () => import("@/views/reseller/Create.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "reseller-rename",
      path: ":reseller/rename",
      component: () => import("@/views/reseller/Rename.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "reseller-update-wholesale-pricing",
      path: ":reseller/updateWholesalePricing",
      component: () => import("@/views/reseller/UpdateWholesalePricing.vue"),
      meta: {
        protected: true,
      },
    },
    {
      name: "reseller-invite-user",
      path: ":reseller/invite-user",
      component: () => import("@/views/reseller/InviteUser.vue"),
      meta: {
        protected: true,
      },
    },
  ],
}

export default resellersRoutes
