const namespaced = true
export default {
  namespaced,
  state: {
    entrances: [],
  },
  mutations: {
    setEntrances(state, val) {
      state.entrances = val
    },
  },
  actions: {
    loadEntrances: async function ({ commit }, val) {
      let entrances = []

      await this._vm.$apiClient
        .get(
          "/api/v2/entrances?pendingInstallation=true&includeImageUrls=false&includeHealthStatus=true",
          { params: { resellerId: val } }
        )
        .then((response) => {
          if (response) entrances = response.data?.entrances
        })

      commit("setEntrances", entrances)
    },
    setEntrances: async ({ commit }, val) => {
      commit("setEntrances", val)
    },
    clearEntrances: async ({ commit }) => {
      commit("setEntrances", [])
    },
  },
}
