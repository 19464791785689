export const setUpdateResidents = async ({ commit }, newValue) => {
  commit('setUpdateResidents', newValue)
}

export const setUpdatePendingResidents = async ({ commit }, newValue) => {
  commit('setUpdatePendingResidents', newValue)
}

export const setUpdateBuildings = async ({ commit }, newValue) => {
  commit('setUpdateBuildings', newValue)
}
