import Vue from 'vue'
import { ToastPlugin, ModalPlugin, FormInputPlugin } from 'bootstrap-vue'
import { Frontegg } from '@frontegg/vue'
import VueCookies from 'vue-cookies'
import VueAzureUploader from 'vue-azure-blob-upload'
import VueAppInsights from 'vue-application-insights'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import http from '@core/http'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.config.devtools = true

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(http)
// Composition API
Vue.use(FormInputPlugin)
Vue.use(VueAzureUploader)
Vue.use(VueCookies)
if (process.env.VUE_APP_APPLICATIONINSIGHTS_CONNECTION_STRING) {
  console.log('trying to configure app insights using', process.env.VUE_APP_APPLICATIONINSIGHTS_CONNECTION_STRING)
  const appInsights = new ApplicationInsights(
    {
      config: {
        connectionString: process.env.VUE_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
        enableCorsCorrelation: true,
        correlationHeaderDomains: ['whoo-east-test.azurewebsites.net', 'whoo-east-blue.azurewebsites.net', 'whoo-east.azurewebsites.net'],
        enableAutoRouteTracking: true,
        enableDebug: true,
        autoTrackPageVisitTime: true,
      },
    },
  )

  appInsights.loadAppInsights()
  appInsights.trackPageView()

  Vue.use(VueAppInsights, {
    appInsights,
    trackInitialPageView: false,
    router,
  })
}

if (Vue.$cookies && Vue.$cookies.config) {
  Vue.$cookies.config('10y')
}

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

router.getRoutes = () => router.options.routes

Vue.use(Frontegg, {
  contextOptions: {
    baseUrl: process.env.VUE_APP_FRONTEGG_BASE_URL,
  },
  router,
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// Global Event Bus
Vue.prototype.$eventBus = new Vue()
