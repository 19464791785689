const defaultState = {
  token: null,
}

export default {
  namespaced: true,
  state: Object.assign({}, defaultState),
  mutations: {
    update(state, data) {
      Object.assign({}, defaultState, data)
    },
    clear(state) {
      Object.assign(state, defaultState)
    },
  },
  actions: {
    clear({ state, commit, rootState, dispatch }) {
      commit('clear')
    },
    update({ state, commit, rootState }, data) {
      commit('update', data)
    },
  },
}