export default {
  name: "manage-all-accounts",
  path: "/all-accounts",
  component: () => import('@/views/account/ManageAccounts.vue'),
  meta: {
    protected: true,
    resource: 'Accounts',
    action: 'list',
    allMode: true,
  }
};
