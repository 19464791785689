export function setUpdateResidents(state, newValue) {
  state.updateResidents = newValue
}

export function setUpdatePendingResidents(state, newValue) {
  state.updatePendingResidents = newValue
}

export function setUpdateBuildings(state, newValue) {
  state.updateBuildings = newValue
}
