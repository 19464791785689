export function setSelectedUnit(state, newValue) {
  state.selectedUnit = newValue
}

export function clearSelectedUnit(state) {
  state.selectedUnit = null
}

export function setSelectedAccount(state, newValue) {
  state.selectedAccount = newValue
}

export function clearSelectedAccount(state) {
  state.selectedAccount = null
}

export function setSelectedProperty(state, newValue) {
  state.selectedProperty = newValue
}

export function clearSelectedProperty(state) {
  state.selectedProperty = null
}