const buildingEntrancesRoutes = {
  name: "buildings-entrances",
  path: "/accounts/:account/properties/:property/buildings-entrances",
  component: () => import("@/views/building/AllEntrances.vue"),
  meta: {
    protected: true,
  },
  children: [
    {
      name: "all-entrance-detail",
      path: ":building/entrances/:entrance",
      component: () => import("@/views/NestedRoute.vue"),
      children: [
        {
          name: "all-entrance-preview",
          path: "preview",
          component: () => import("@/views/entrance/Preview.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "all-view-entrance-camera",
          path: "view-camera",
          component: () => import("@/views/entrance/ViewEntranceCamera.vue"),
          meta: {
            protected: true,
          },
        },
      ],
      meta: {
        protected: true,
      },
    },
  ],
};

export default buildingEntrancesRoutes;
